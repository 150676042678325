import Vue from "vue";
import jsPDF from 'jspdf'
 
Vue.mixin({
  computed: {
  
  },

  methods: {
     
    gCanvas(op={}) {
    
      this.$toast.add({
        severity:'info',
        summary: 'กำลัง Capture หน้าจอ',
        detail: '',
        life: 5000,
      });
   

      let name = '';
        if(op.name) {
                name = name + "-"+op.name
        }

      let ref = 'printContent';
      if(op.ref) {
        ref = op.ref;
      } 
      op.ref = ref
      op.name = name

      const el = this.$refs[ref];  
      el.style.width = '1200px'; 
      el.style.fontFeatureSettings = '"liga" 0';

    

      let $vm = this

      setTimeout(function(){

        console.log(op)
        
        if(op.type=='image') { 
        $vm.printThis(op)
        }

        if(op.type=='pdf') { 
          $vm.getPdf(op)
          }
        
      }, 2000);

    },
    

    async printThis(op={}) {
      
      const el = this.$refs[op.ref]; 
        
      const options = {
        type: "dataURL",
        quality: 4,
  scale: 5, 
  allowTaint: true
      };
      const printCanvas = await  html2canvas(el, options);
      

      const link = document.createElement("a");
      link.setAttribute("download", op.name+".png");
      link.setAttribute(
        "href",
        printCanvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream")
      );
      link.click();
      this.$LOADING = null

      
    },

    getPdf(op={}) {
     
 
      let $vm = this;
      const doc = new jsPDF({
        orientation: 'p',
        unit: 'px',
        format: 'a4',
         
        hotfixes: ['px_scaling'],
      });

  
      const options = { 
        quality: 1,
       
  scale: 1, 
  logging: true, letterRendering: 1,  allowTaint: false, useCORS: true 
      };

      var el = this.$refs[op.ref];  
      
html2canvas(el,options).then(canvas => {  
  // Few necessary setting options  

  const contentDataURL = canvas.toDataURL('image/png')  
  var margin = 2;
  var imgWidth = 210 - 2*margin; 
  var pageHeight = 295;  
  var imgHeight = canvas.height * imgWidth / canvas.width;
  var heightLeft = imgHeight;

  var doc = new jsPDF('p', 'mm');
  var position = 0;

  doc.addImage(contentDataURL, 'PNG', margin, position, imgWidth, imgHeight);

  heightLeft -= pageHeight;

  while (heightLeft >= 0) {
    position = heightLeft - imgHeight;
    doc.addPage();
    doc.addImage(contentDataURL, 'PNG', margin, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
  }
  doc.save( op.name+'.pdf');
  $vm.$LOADING = null

});

    }
  },
});
