import Vue from "vue";
 
import moment from "moment";

require("moment/locale/th");
 var momentDurationFormatSetup = require("moment-duration-format");
 
 let globalData = new Vue({
   data: {
    $FADE:'animate__animated animate__fadeIn',
     $MODAL: null, 
     $NEW_MEMBER: 0, 
     $NEW_RETEST: 0,   
     $STICKY:0,
     $UPDATE:null,
     $POP_MEMBER:null,
     $POP_DEALER:null,
     $POP_BRANCH:null,
     $RE_TOTAL_ALERT:null,
   },
 });



Vue.mixin({
  watch:{
    $route (to, from){
        this.$DATE_TEXT =null
    }
} ,
  computed: {
    $FADE: {
      get: function() {
        return globalData.$data.$FADE;
      } 
    },

    $RE_TOTAL_ALERT: {
      get: function() {
        return globalData.$data.$RE_TOTAL_ALERT
      },
      set: function(newValue) {
        globalData.$data.$RE_TOTAL_ALERT = newValue;
      },
    },


    $POP_DEALER: {
      get: function() {
        return globalData.$data.$POP_DEALER
      },
      set: function(newValue) {
        globalData.$data.$POP_DEALER = newValue;
      },
    },

    $POP_BRANCH: {
      get: function() {
        return globalData.$data.$POP_BRANCH
      },
      set: function(newValue) {
        globalData.$data.$POP_BRANCH = newValue;
      },
    },

    $POP_MEMBER: {
      get: function() {
        return globalData.$data.$POP_MEMBER
      },
      set: function(newValue) {
        globalData.$data.$POP_MEMBER = newValue;
      },
    },

    $UPDATE: {
      get: function() {
        return globalData.$data.$UPDATE;
      },
      set: function(newValue) {
        globalData.$data.$UPDATE = newValue;
      },
    },
    $STICKY: {
      get: function() {
        return globalData.$data.$STICKY;
      },
      set: function(newValue) {
        globalData.$data.$STICKY = newValue;
      },
    },

    $NEW_RETEST: {
      get: function() {
        return globalData.$data.$NEW_RETEST;
      },
      set: function(newValue) {
        globalData.$data.$NEW_RETEST = newValue;
      },
    },
 
    $NEW_MEMBER: {
      get: function() {
        return globalData.$data.$NEW_MEMBER;
      },
      set: function(newValue) {
        globalData.$data.$NEW_MEMBER = newValue;
      },
    },
  
    $MODAL: {
      get: function() {
        return globalData.$data.$MODAL;
      },
      set: function(newValue) {
        globalData.$data.$MODAL = newValue;
      },
    },
  },

  methods: {
    prettyJson(json) {
      json = JSON.stringify(json, undefined, 4);
  
      json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
      return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
          var cls = 'number';
          if (/^"/.test(match)) {
              if (/:$/.test(match)) {
                  cls = 'key';
              } else {
                  cls = 'string';
              }
          } else if (/true|false/.test(match)) {
              cls = 'boolean';
          } else if (/null/.test(match)) {
              cls = 'null';
          }
          return '<span class="' + cls + '">' + match + '</span>';
      });
  },
    
  
    totalAlert() {

      

    },
  

    dateName() {

      let now = new Date();
 
  
var dateStringWithTime = this.$moment(now).format("YYYY-MM-DD_HH-MM-SS");
 
return dateStringWithTime;

    },
    
    download_file(fileURL, fileName) {
      
      // for non-IE
      if (!window.ActiveXObject) {
          var save = document.createElement('a');
          save.href = fileURL;
          save.target = '_blank';
          var filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
          save.download = fileName || filename;
          if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
              document.location = save.href;
              // window event not working here
          } else {
              var evt = new MouseEvent('click', {
                  'view': window,
                  'bubbles': true,
                  'cancelable': false
              });
              save.dispatchEvent(evt);
              (window.URL || window.webkitURL).revokeObjectURL(save.href);
          }
      }

      // for IE < 11
      else if (!!window.ActiveXObject && document.execCommand) {
          var _window = window.open(fileURL, '_blank');
          _window.document.close();
          _window.document.execCommand('SaveAs', true, fileName || fileURL)
          _window.close();
      }
  },

 
   

  },
});
