/* eslint-disable vue/multi-word-component-names */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
 import vuetify from "./plugins/vuetify";
 import "vuetify/dist/vuetify.min.css"; 

 



import Loading from '@/inc/Comp/Loading'

Vue.component("Loading", Loading);

import PageIn from '@/inc/Comp/PageIn'
Vue.component("PageIn",PageIn);

  
import Delete from '@/inc/Comp/Delete'  
 
 
 
import DHeader from '@/inc/Comp/DHeader'
 

Vue.component("DHeader",DHeader);
 
  
// eslint-disable-next-line vue/multi-word-component-names
Vue.component("Delete",Delete); 

 

 import { serialize } from "object-to-formdata";
 Vue.prototype.$formData = serialize;




 import UploadPicture from "@/inc/CsCenter/UploadPicture"; 
 Vue.component("UploadPicture", UploadPicture);




 
import moment from "moment";

require("moment/locale/th");

// Choose Locale
moment.locale("th");

Vue.prototype.$moment = moment;
 
Vue.config.productionTip = false

 
 

import axios from "axios";
import "./Core/function";
import "./plugins/prime.js"; 
import "./Core/canvas.js"; 
import "./Core/adminFunction.js";
import "./Core/custom_function.js";
import "./assets/css/grid.css";

import "./assets/css/table.css";
import "./assets/css/core.css";
import "./assets/css/stats.css";
import "./assets/css/custom.css";

Vue.prototype.$axios = axios;

 

import { api } from './plugins/axiosGet'
Vue.prototype.$api = api;

import { apiData } from './plugins/axiosData'
Vue.prototype.$apiData = apiData;
 

import { apiAuth } from './plugins/axiosAuth'
Vue.prototype.$apiAuth = apiAuth;

router.beforeEach(function(to, from, next) {
  window.scrollTo(0, 0);
  next();
});

new Vue({
  router,
  vuetify, 
  render: (h) => h(App),
}).$mount("#app");
