import Vue from "vue";
//import firebase from "firebase";
//var VueCookie = require("vue-cookie");
// Tell Vue to use the plugin
//Vue.use(VueCookie);

let globalData = new Vue({
  data: {
    $AUTH: null,
    $ADMIN: null,
    $RE_CHECK_ADMIN: null,
    $SUB_GROUP: { sub_group: null, sub_group_title: null },
    $ADMIN_ID: null,
    $VISIT_ID: null,
    $MCNET: true,  
    $BY_PASS_TOKEN:null
  },
});

Vue.mixin({
  computed: {
    access() {
      return this.$route.params.access;
    },

    item_id() {
      return this.$route.params.item_id;
    },

    slug() {
      return this.$route.params.slug;
    },

    $BY_PASS_TOKEN: {
      get: function() {
        return globalData.$data.$BY_PASS_TOKEN;
      },
      set: function(newValue) {
        globalData.$data.$BY_PASS_TOKEN = newValue;
      },
    },



    $MCNET: {
      get: function() {
        return globalData.$data.$MCNET;
      },
      set: function(newValue) {
        globalData.$data.$MCNET = newValue;
      },
    },

    $VISIT_ID: {
      get: function() {
        return globalData.$data.$VISIT_ID;
      },
      set: function(newValue) {
        globalData.$data.$VISIT_ID = newValue;
      },
    },

    $ADMIN: {
      get: function() {
        return globalData.$data.$ADMIN;
      },
      set: function(newValue) {
        globalData.$data.$ADMIN = newValue;
      },
    },

    $RE_CHECK_ADMIN: {
      get: function() {
        return globalData.$data.$RE_CHECK_ADMIN;
      },
      set: function(newValue) {
        globalData.$data.$RE_CHECK_ADMIN = newValue;
      },
    },

    $SUB_GROUP: {
      get: function() {
        return globalData.$data.$SUB_GROUP;
      },
      set: function(newValue) {
        globalData.$data.$SUB_GROUP = newValue;
      },
    },

    $AUTH: {
      get: function() {
        return globalData.$data.$AUTH;
      },
      set: function(newValue) {
        globalData.$data.$AUTH = newValue;
      },
    },
    $ADMIN_ID: {
      get: function() {
        return globalData.$data.$ADMIN_ID;
      },
      set: function(newValue) {
        globalData.$data.$ADMIN_ID = newValue;
      },
    },
  },

  methods: {
    topAdmin() {
      let top = false;

      if (this.$ADMIN.group_id == 1) {
        top = true;
      }

      if (this.$ADMIN.admin_type == "top") {
        top = true;
      }

      if (this.$ADMIN_ID == 1) {
        top = true;
      }

      return top;
    },

  
    allowSite() {
      let allow = null;

      if (this.$ADMIN) {
        if (this.$ADMIN.admin_type) {
          allow = true;
        }
      }

      console.log(allow);

      return allow;
    },

    goLogout() {
      let $vm = this;
      $vm.$ADMIN_ID = null;
      this.setCookie(this.$INFO.TOKEN_NAME, null);
      //this.setCookie("C_ID", null);
      //this.$C_ID = null;
     

      $vm.$ADMIN = null;

      $vm.$AUTH = null;

/*       $vm.dbInsert('visit/visit_' + $vm.$VISIT_ID+'/site/'+$vm.$SITE_ID, {path: "/"}) 
      $vm.dbInsert('visit/visit_' + $vm.$VISIT_ID , {logout: true})
 */
      window.parent.postMessage({
        logout: true,
      //  site_id:$vm.$SITE_ID,
      //  path: "/"
    },
        "*"  
    );



    },
  },
});
