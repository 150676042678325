import axios from "axios";
export function api(url, data={},option ) {
 
  
  let $vm = this;

  let loading = 'full'

  if(data) { 
  if(data.loading) {
    loading = data.loading
  }
}

  if (loading == "normal") {
    this.$LOADING = true;
  }
  if (loading == "full") {
    this.$FULL_LOADING = true;
  }

 
 
  this.$UPDATE_HEIGHT = null
 

 
  let API = axios.create({
    baseURL: process.env.VUE_APP_API_CENTER + "apiBack/",
    timeout: 60 * 4 * 1000,
  });

  let token = $vm.getCookie(this.$INFO.TOKEN_NAME)

  if (this.$BY_PASS_TOKEN) {
    token =this.$BY_PASS_TOKEN
  }

  API.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  const formData = this.$formData(data);
  return API
    .post(  url, $vm.formData(formData, true))
    .then(function(res) {
      $vm.checkApi(res,url);
      $vm.$UPDATE_HEIGHT  = true
      
      return res.data;
    })
    .catch(function(error) {
      console.log(error);
      $vm.$FULL_LOADING = null;
      $vm.$LOADING = null;
    });
  }
 
