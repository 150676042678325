<template>
 
    <v-toolbar :dark="dark" :color="color" height="40" style="max-height:40px;" :flat="flat">

        <v-toolbar-title>{{title}}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-toolbar-items>
            <v-btn icon :dark="dark" @click="close()">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar-items>
    </v-toolbar>
 
</template>

<script>
export default {

    components: {

    },
    props: {

        title: null,
        color: {
            type: String,
            default: 'teal'
        },
        dark: {
            type: Boolean,
            default: true
        },
        
        flat: {
            type: Boolean,
            default: false
        },

    },

    methods: {
        close() {
            this.$emit('close', true);
        } 
    },

};
</script>
