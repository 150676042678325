<template>
<div>
    <div style="margin-top:10%;" v-if="!$ADMIN_ID">
        <div class="row">
            <div class="col-12" v-if="checked">
                <div class="login-logo text-center">
                     <h1>Dog Smile</h1>
                     </div>
                <v-card width="300" class="mx-auto mt-5 rounded-md loginBox">
                    <v-toolbar color="#0F5CA9" dark height="40px">
                        <v-row>
                            <v-col class="d-flex justify-space-around text-white">
                                Admin เข้าระบบ
                            </v-col>
                        </v-row>

                    </v-toolbar>
                    <div class="mx-auto text-center box boxLogin" :class="boxLoginClass">

                        <v-list-item three-line>
                            <v-list-item-content>

                                <div v-html="loginMsg" class="mb-2"></div>

                                <v-form ref="form" v-model="valid" lazy-validation>
                                    <v-text-field v-model="username" :rules="nameRules" label="Email" required outlined type="email"></v-text-field>

                                    <v-text-field type="password" v-model="password" :rules="passwordRules" label="รหัสผ่าน" required outlined class="mt-2"></v-text-field>

                                    <div class="text-center mt-2">
                                        <v-btn :disabled="!valid" color="blue-grey darken-3" :dark="(!valid ? false : true)" @click="validate " rounded block>
                                            เข้าระบบ
                                        </v-btn>
                                    </div>

                                </v-form>

                            </v-list-item-content>

                        </v-list-item>

                    </div>
                </v-card>
            </div>

        </div>
    </div>

</div>
</template>

<script>
import AdminUpdate from '@/views/Admin/AdminUpdate'
export default {

    components: {
        AdminUpdate
    },
    props: {

    },

    data() {
        return {

            allowSiteIdList() {
                return this.$ALLOW_ADMIN
            },

            valid: true,
            checked: null,

            nameRules: [
                v => !!v || 'โปรดระบุ Email  ด้วยค่ะ'
            ],

            passwordRules: [
                v => !!v || 'โปรดระบุ รหัสผ่านด้วยค่ะ'
            ],
            select: null,

            boxLoginClass: null,
            logingIn: false,
            loginMsg: null,
            maximizedToggle: true,
            username: '',
            password: '',
            loginBox: false,
            left: false,
            right: false
        }
    },

    computed: {
        reCheckAdm() {
            return this.$RE_CHECK_ADMIN
        },
        byPassToken() {
            return this.$route.params.token;
        },

    },
    watch: {

        reCheckAdm() {
            this.checkAdmin()
        },
        checked(val) {
            console.log(this.$VISIT_ID)

            if (val) {
                if (this.$VISIT_ID) {
                    /*     this.dbInsert('visit/visit_' + this.$VISIT_ID + '_frame', {
                         frameLoad: true
                    }) */

                    window.parent.postMessage({
                            frameLoad: true
                        },
                        "*"
                    );

                }

            }

        }

    },
    methods: {

        validate() {
            this.$refs.form.validate()
            let $vm = this
            setTimeout(function () {
                if ($vm.valid == true) {
                    console.log(this.valid)
                    $vm.login()
                }
            }, 100);
        },

        setAdmin(data) {

            let $vm = this

            console.log(data.admin)

            $vm.$ADMIN_ID = data.admin.admin_id

            $vm.$MCNET = data.mcNet
            console.log($vm.$MCNET)

            this.setCookie(this.$INFO.TOKEN_NAME, data.token)

            if (data.admin) {

                $vm.$ADMIN = data.admin;

          

                $vm.$AUTH = true
                let view = data.admin
                view.token = null
              

                $vm.checked = true

       
            }

        },

        login() {
            let $vm = this;

            $vm.loginMsg = "";
            $vm.$ADMIN = null
            this.setCookie(this.$INFO.TOKEN_NAME, null)
            let data = {
                username: $vm.username,
                password: $vm.password
            }
            this.$apiAuth("admin/login", data).then(function (data) {
                $vm.logingIn = false
                $vm.loginMsg = data.loginMsg;

                if (data.admin_check == false) {
                    $vm.loginMsg = data.loginMsg
                    setTimeout(function () {
                        $vm.boxLoginClass = "";
                    }, 1000);

                } else {
                    $vm.setAdmin(data)

                }

            })

        },

        checkAdmin() {
            let $vm = this;

            this.$apiAuth("admin/check").then(function (data) {

                $vm.checked = true
                if (data.admin) {
                    $vm.setAdmin(data)
                }

            })

        },

   

        setToken(admin) {

        }

    },
    created: function () {

     

        if (!this.$ADMIN_ID) {
            this.checkAdmin()
        }
 
    },

}
</script>
