<template>
<div v-if="$ADMIN_ID">
   xx
</div>
</template>

<script>
export default {

};
</script>
