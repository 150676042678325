<template>
<div>
    <div class="mainHeader">

        <v-app-bar color="white" dense fixed elevation="0">

            <v-toolbar-title>
               <h3>Dog Smile</h3>
            </v-toolbar-title>

            <v-spacer></v-spacer>

     
  

            <span >

                    <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn text v-bind="attrs" v-on="on">
                        ผู้ดูแล
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item :to="{name:'AdminDo',params:{slug:'list' }}">
                        <v-list-item-title>การจัดการ</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{name:'AdminDo',params:{slug:'history' }}">
                        <v-list-item-title>ประวัติ</v-list-item-title>
                    </v-list-item>
                     
                </v-list>
            </v-menu>


               
            </span>
            <AdminMenu />
        </v-app-bar>

        <v-card :height="40" class="mt-12 pl-2 pr-2" elevation="0" v-if="menu">

            <v-tabs dark background-color="blue darken-2" show-arrows hide-slider height="40">
                <v-tabs-slider color="#ffffff"></v-tabs-slider>

                <v-tab v-for="(item, index) in menu" :key="index">

                    <v-menu transition="scroll-y-transition" open-on-hover bottom offset-y v-if="item.sub">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="blue darken-2" dark v-bind="attrs" v-on="on" depressed>
                                {{item.title}}
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item v-for="(mn, index) in item.sub" :key="index" :to="mn.to">
                                <v-list-item-title>{{mn.title}}</v-list-item-title>
                            </v-list-item>

                        </v-list>
                    </v-menu>

                    <v-btn text dark depressed v-else :to="(item.to ) ? item.to : null">
                        {{item.title}}
                    </v-btn>

                </v-tab>

            </v-tabs>

        </v-card>

    </div>


</div>
</template>

<script>
import AdminMenu from '@/Core/adminMenu'
import MenuDropdown from '@/inc/MenuDropdown'

export default {

    components: {
        AdminMenu,
        MenuDropdown

    },

    data: () => ({
        menu: null,
        total: {
            comment: 0,
            inbox: 0,
        }

    }),

    computed: {
        currentRouteName() {
            return this.$route.name;
        },

    },

   
    methods: {

        getMenu() {

            let url = "misc/nav";

            let $vm = this;

            this.$api(url, this.data).then(function (data) {

                $vm.menu = data.menu

            })

        },
    

    },
    created() {

        this.getMenu()
       

    },
};
</script>
