<template>
<div v-if="listInfo">  
    <div v-if="listInfo.totalItems == 0" class="noList">
        {{listInfo.noText}}  
    </div>
    <div v-else> 
        <Paginator v-if="(listInfo.totalItems/listInfo.perPage) > 1" :rows="listInfo.perPage" :totalRecords="listInfo.totalItems" @page="onPage($event)"></Paginator>
    </div>
</div>
</template>

<script>
export default {

    props: {

        listInfo: null,

    },
    methods: {

        onPage(event) {
            this.$emit('setPage', event);

        },

    },

};
</script>
