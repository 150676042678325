import axios from "axios";
export function apiAuth(url, data, loading = "full") {
 
  let $vm = this;

  if (loading == "normal") {
    this.$LOADING = true;
  }
  if (loading == "full") {
    this.$FULL_LOADING = true;
  }
  
 
 
  let API = axios.create({
    baseURL: process.env.VUE_APP_API_CENTER + "apiBack/",
    timeout: 60 * 4 * 1000,
  });

  let token = $vm.getCookie(this.$INFO.TOKEN_NAME)

 

  API.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  const formData = this.$formData(data);
  return API
    .post(  url, $vm.formData(formData, true))
    .then(function(res) {
      $vm.checkApi(res,url);
      return res.data;
    })
    .catch(function(error) {
      console.log(error);
      $vm.$FULL_LOADING = null;
      $vm.$LOADING = null;
    });
  }
 
