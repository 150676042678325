<template>
<div v-if="$ADMIN_ID && admin">

    <v-menu offset-y>
        <template v-slot:activator="{ attrs, on }">
            <v-btn text color="teal" v-bind="attrs" v-on="on" class="no-text-transform">
               {{ $ADMIN.displayName }}
            </v-btn>
        </template>

        <v-list>

            <v-list-item :to="{name:'AccountDo',params:{slug:'ChangePassword'}}">
                <v-list-item-title> เปลี่ยนรหัสผ่าน</v-list-item-title>
            </v-list-item>

            <v-list-item @click="goLogout()">
                <v-list-item-title> ออกจากระบบ</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>

</div>
</template>

<script>
export default {
    components: {

        admin: null,

    },
    data: function () {
        return {

            admin: null
        };
    },
    name: 'MainHeader',

    computed: {
        getAdminData() {

            return this.$ADMIN;
        },
        getAdminId() {
            return this.$ADMIN_ID;
        },
    },
    watch: {
        getAdminData(val) {
            if (val) {
                this.admin = this.getAdminData;
                console.log('XXX')
                console.log(this.admin)

            }
        },
    },

    methods: {

      
    },

    created: function () {
        this.admin = this.getAdminData;

    },

}
</script>
