<template>
<div>
    <v-app>
        <Core />
        <div v-if="$INFO">
            <Header v-if="$AUTH" />

            <Auth />
            <div>

                <Toast position="top-center" />

                <v-overlay :value="$FULL_LOADING">
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                </v-overlay>

                <div  >
             

                    <router-view :key="$KEY" v-if="$AUTH"></router-view>

                </div>
               

                <v-snackbar v-model="$ERROR" timeout="-1" :multi-line="true" height="200">
                    {{ $ERROR_TEXT}}

                    <template v-slot:action="{ attrs }">
                        <v-btn color="yellow" text v-bind="attrs" @click="$ERROR = false">
                            Close
                        </v-btn>
                    </template>
                </v-snackbar>
            </div>
        </div>
    </v-app>
</div>
</template>

<script>
import Auth from './Core/Auth.vue'
import Header from './inc/Header';
import MainMenu from '@/inc/MainMenu'
import Core from '@/Core/Core'
 

export default {
    name: 'App',

    components: {
        Auth,
        Core,
        MainMenu,
       
        Header

    },

    data: () => ({

    }),

    methods: {

    },
    created() {

    },

};
</script>
