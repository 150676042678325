<template>
<div> 
    <v-btn @click="updateList()" outlined small color="blue-grey lighten-1">Update รายชื่อ Admin จาก Elearning</v-btn>
 
</div>
</template>

<script>
export default { 
    methods: {

        updateList() {

            let $vm = this
            this.$FULL_LOADING = true

            let url = "admin/updateList";

            this.$BACK
                .post(url, this.formData())
                .then(function (res) {
                    $vm.checkApi(res)
                    $vm.$emit('update',true)
                })
                .catch(function (error) {
                    console.log(error);

                });

        },
 

    } 
};
</script>
