<template>
<div>

</div>
</template>

<script>
export default {

    data: () => ({

    }),
    computed: {

        visit_id() {
            return this.$VISIT_ID
        },

        currentPage() {
            return this.$router.currentRoute.path
        },

    },
 

  

    methods: {

    
        getCsInfo() {
            let $vm = this
            let data = {
                side: 'back'
            }
            this.$apiData('main/info', data).then(function (data) {

                $vm.$INFO = data.INFO

            })

        }

    },
    created() {

        this.getCsInfo();

    },

};
</script>
