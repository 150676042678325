import Vue from "vue";
 
 

let globalData = new Vue({
  data: {
    $UPDATE_HEIGHT:null,
    $DATE_TEXT:null,
    $DATE_TEXT_POP:null,
    $LOADING: false, 
    $FULL_LOADING: false,
    $ERROR_TEXT:null,
    $ERROR:null,
    $INFO: null,
   
  },
});
Vue.mixin({
  computed: {
    $DATE_TEXT: {
      get: function() {
        return globalData.$data.$DATE_TEXT;
      },
      set: function(newValue) {
        globalData.$data.$DATE_TEXT = newValue;
      },
    },

    $DATE_TEXT_POP: {
      get: function() {
        return globalData.$data.$DATE_TEXT_POP;
      },
      set: function(newValue) {
        globalData.$data.$DATE_TEXT_POP = newValue;
      },
    },

    $UPDATE_HEIGHT: {
      get: function() {
        return globalData.$data.$UPDATE_HEIGHT;
      },
      set: function(newValue) {
        globalData.$data.$UPDATE_HEIGHT = newValue;
      },
    },

    $INFO: {
      get: function() {
        return globalData.$data.$INFO;
      },
      set: function(newValue) {
        globalData.$data.$INFO = newValue;
      },
    },
    $ERROR_TEXT: {
      get: function() {
        return globalData.$data.$ERROR_TEXT;
      },
      set: function(newValue) {
        globalData.$data.$ERROR_TEXT = newValue;
      },
    },
    $ERROR: {
      get: function() {
        return globalData.$data.$ERROR;
      },
      set: function(newValue) {
        globalData.$data.$ERROR = newValue;
      },
    },

    $LOADING: {
      get: function() {
        return globalData.$data.$LOADING;
      },
      set: function(newValue) {
        globalData.$data.$LOADING = newValue;
      },
    },

    $FULL_LOADING: {
      get: function() {
        return globalData.$data.$FULL_LOADING;
      },
      set: function(newValue) {
        globalData.$data.$FULL_LOADING = newValue;
      },
    },
   
    
  },

  methods: {
 
    checkArray(value, array) {
      var status = null;

      for (var i = 0; i < array.length; i++) {
        var name = array[i];
        if (name == value) {
          status = true;
          break;
        }
      }
      return status;
    },

    formData(formData ) {
      let $vm = this;
      let formD = null;
      if (!formData) {
        formD = new FormData();
      } else {
        formD = formData;
      }  
    
   
     
  
    
       
      return formD;
    },

    

    getFileUpload(file) {

      let size =  file.size  / (1024*1024)

      return {name:file.name,size:size.toFixed(2)}

    },

    checkApi(res,url) {
      this.$FULL_LOADING = null;
     
  if(this.$MCNET) { 
     console.log(url)
      console.log(res.data);
      if (res.data.reqUrl) {
        console.log(res.data.reqUrl);
        res.data.reqUrl = null;
      }
      }

      
      if(!res.data.rs) {
        this.$ERROR = true
        this.$ERROR_TEXT = res.data
      }

      if(res.data.text) {
        if(res.data.text != 'none')  {
          this.alert(res)
        }
      }

    },
    setCookie(key, value) {
      localStorage.setItem(this.$C_COOKIE+key, value);
    },
    getCookie(key) {
      return localStorage.getItem(this.$C_COOKIE+key);
    },
    removeA(arr) {
      var what,
        a = arguments,
        L = a.length,
        ax;
      while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax = arr.indexOf(what)) !== -1) {
          arr.splice(ax, 1);
        }
      }
      return arr;
    },
    textSuccess(text) {
      return text + " สำเร็จ";
    },
    textWarn(text) {
      return "ไม่สามารถ " + text + " ได้ โปรดลองใหม่อีกครั้งภายหลัง";
    },
    alert(res) {
      var type = "";
      var msg = "";
     
      if (res.data.re == "success") {
        type = "success";
        msg = this.textSuccess(res.data.text);
      } else {
        type = "warn";
        msg = this.textWarn(res.data.text);
      }
      let desc = '';
      if(res.data.desc) {
        desc = res.data.desc
      }

      this.$toast.add({
        severity: type,
        summary: msg,
        detail: desc,
        life: 3000,
      });
    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },

    itemSplit(items, page, perPage) {
      let it = [];
      if (items) {
        let from = page * perPage - perPage;
        let to = page * perPage;

        it = items.slice(from, to);
      }

      return it;
    },

    textareaString(str) {
      var regex = /<br\s*[\/]?>/gi;
      return str.replace(regex, "");
    },

    lg(data) {
      console.log(data);
    },
    random() {
      return Math.floor(Math.random() * 10000 + 1);
    },

    ie11() {
      var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
      return isIE11;
    },
 
    jsonInt(json) {

    if(json) {  
var result = json.map(function (x) { 
  return parseInt(x, 10); 
});
    }

    else{
      var result = []
    }

return result;


    },
 
    escapeHtml(text) {
      if(text){ 
      return text
          .replace(/&/g, "&amp;")
          .replace(/</g, "&lt;")
          .replace(/>/g, "&gt;")
          .replace(/"/g, "&quot;")
          .replace(/'/g, "&#039;");
      }
      else{
        return ''
      }
    },

 
 

    itemSplit(items, page, perPage) {
      let it = [];
      if (items) {
        let from = page * perPage - perPage;
        let to = page * perPage;

        it = items.slice(from, to);
      }

      return it;
    },
    go(goto) {
      var route = this.$router.currentRoute.fullPath;
      Vue.prototype.$SAME_PAGE = true;
      if (goto != route) {
        this.$router.replace(goto);
      }
    },


  cPath(){

    return this.$router.currentRoute.path

  },


  },
});
