<template>
<span v-if="items">
    <span v-for="(item, index) in items" :key="index">
        <v-menu transition="scroll-y-transition" open-on-hover bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark v-bind="attrs" v-on="on">
                    {{item.title}}
                </v-btn>
            </template>

            <v-list>
                <v-list-item>
                    <v-list-item-title>xxx</v-list-item-title>
                    <v-list-item-title>xxx</v-list-item-title>
                    <v-list-item-title>xxx</v-list-item-title>
                    <v-list-item-title>xxx</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </span>
</span>
</template>

<script>
export default {

    data: () => ({

        items: null
    }),

    methods: {

        getItems() {

            let url = "misc/nav";

            let $vm = this;

            this.$api(url, this.data).then(function (data) {

                $vm.items = data.menu

            })

        }

    },
    created() {
        this.getItems()

    },
};
</script>
