import axios from "axios";
export function apiData(url, data ) {
 
  let $vm = this;
 
  if (!data) {
    let data = new Object();
  }

 
 
  let API = axios.create({
    baseURL: process.env.VUE_APP_API_CENTER + "apiBack/",
    timeout: 60 * 4 * 1000,
  });

  API.defaults.headers.common['Authorization'] = `Bearer ${process.env.VUE_APP_DATA_TOKEN}`;

  

  const formData = this.$formData(data);
  return API
    .post(  url, $vm.formData(formData, true))
    .then(function(res) { 
      $vm.checkApi(res,url);
      return res.data;
    })
    .catch(function(error) {
      console.log(error); 
    });
}
